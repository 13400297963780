.swiper{
    /*width: 1119px;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-inline:  16px;
    gap:12px;
}

.swiper-slide{
    /*width: auto !important;*/
    height: 382px;
}
.swiper-wrapper{
    height: 382px;
    padding-inline: 10px;
}

.swiper-pagination-bullet{
    margin-inline: 8px !important;
    width: 8px !important;
    height: 8px !important;
}
.swiper-pagination-bullet-active {
    background: #4A3411 !important;
}